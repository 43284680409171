function readyFn(jQuery) {
  
    // close edit form
    // this form opens when you click 'Edit' on an exhibit
    // see app/views/exhibits/edit.js.erb
    $( ".exhibit-form-edit-close" ).click(function() {
  
      // hide edit form, show new form
      $( ".exhibit-form-edit" ).addClass('hidden');
      $( ".exhibit-form-new" ).removeClass('hidden');
  
      // clear the pending edits
      $('.exhibit-form-edit .exhibit-name').val('');
    });
  
    // make forms sticky
    $(window).scroll(function() {
      var distanceFromTop = $(this).scrollTop();
      if (distanceFromTop >= 70) {
          $('.exhibit-form').addClass('fixed');
          $('#petition-assembler .right').addClass('hidden');
      } else {
          $('.exhibit-form').removeClass('fixed');
          $('#petition-assembler .right').removeClass('hidden');
      }
    });
  };
  
  $(document).on('turbolinks:load', readyFn);
  