/// if you don't have turbolinks then use this:
//// document.addEventListener('DOMContentLoaded', () => {

import $ from 'jquery';

window.addEventListener('turbolinks:load', () => {
  $('.select2').select2({
    tags: true,
    tokenSeparators: [',', ' ']
  });
})
