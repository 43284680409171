function readyFn(jQuery) {

  // toggle new petition item form between "exhibit", "section", and "template" based on radio value
  $('.petition-item-kind').change(function() {
    if ($('#petition_item_kind_exhibit').is(':checked')){
      $('.petition-item-template').parent('.form-group').hide();
      $(".petition-item-name").addClass("hidden");
      $(".petition-item-description").removeClass("hidden");
      $(".petition-item-exhibit").removeClass("hidden");
      $(".petition-item-notes-to-client").removeClass("hidden");
    } else if ($('#petition_item_kind_section').is(':checked')) {
      $('.petition-item-template').parent('.form-group').hide();
      $(".petition-item-name").removeClass("hidden");
      $(".petition-item-description").removeClass("hidden");
      $(".petition-item-exhibit").addClass("hidden");
      $(".petition-item-notes-to-client").removeClass("hidden");
    } else if ($('#petition_item_kind_template').is(':checked')) {
      $('.petition-item-template').parent('.form-group').show();
      $(".petition-item-name").addClass("hidden");
      $(".petition-item-description").addClass("hidden");
      $(".petition-item-exhibit").addClass("hidden");
      $(".petition-item-notes-to-client").addClass("hidden");
    }
  });

  // close edit form
  // this form opens when you click 'Edit' on a petition_item
  // see app/views/petition_items/edit.js.erb
  $( ".petition-item-form-edit-close" ).click(function() {

    // set radio button back to exhibit
    $("#petition_item_kind_exhibit").prop("checked", true);

    // hide edit form, show new form
    $( ".petition-item-form-edit" ).addClass('hidden');
    $( ".petition-item-form-new" ).removeClass('hidden');

    // clear the pending edits
    $('.petition-item-form-edit .petition-item-description').html('');
    $('.petition-item-form-edit .petition-item-google-doc-id').val('');

    // show the correct fields
    $('.petition-item-template').parent('.form-group').hide();
    $(".petition-item-name").removeClass("hidden");
    $(".petition-item-description").removeClass("hidden");
  });

  // open notes form
  $( ".btn-petition-notes.closed" ).click(function() {
    $( ".petition-item-form-edit" ).addClass('hidden');
    $( ".petition-item-form-new" ).addClass('hidden');
    $( ".petition-form-notes" ).removeClass('hidden');
    $( ".btn-petition-notes" ).removeClass('closed');
    $( ".btn-petition-notes" ).addClass('open');
  });

  $( ".petition-form-notes-close" ).click(function() {
    $( ".petition-form-notes" ).addClass('hidden');
    $( ".petition-item-form-new" ).removeClass('hidden');
  });

  // make forms sticky
  $(window).scroll(function() {
    var distanceFromTop = $(this).scrollTop();
    if (distanceFromTop >= 70) {
        $('.petition-form').addClass('fixed');
        $('#petition-assembler .right').addClass('hidden');
    } else {
        $('.petition-form').removeClass('fixed');
        $('#petition-assembler .right').removeClass('hidden');
    }
  });
};

$(document).on('turbolinks:load', readyFn);
