function readyFn(jQuery) {
  
  // close edit form
  // this form opens when you click 'Edit' on an component
  // see app/views/components/edit.js.erb
  $( ".component-form-edit-close" ).click(function() {

    // hide edit form, show new form
    $( ".component-form-edit" ).addClass('hidden');
    $( ".component-form-new" ).removeClass('hidden');

    // clear the pending edits
    $('.component-form-edit .component-name').val('');
    $('.component-form-edit .component-google-doc-id').val('');
    $('.component-form-edit .component-google-file-id').val('');
  });

  // make forms sticky
  $(window).scroll(function() {
    var distanceFromTop = $(this).scrollTop();
    if (distanceFromTop >= 70) {
        $('.component-form').addClass('fixed');
        $('#petition-assembler .right').addClass('hidden');
    } else {
        $('.component-form').removeClass('fixed');
        $('#petition-assembler .right').removeClass('hidden');
    }
  });
};

$(document).on('turbolinks:load', readyFn);
