import Rails from '@rails/ujs';

function readyFn(jQuery) {

  // autosave
  if( $('#client_assessment_form').length ){
    window.setInterval(function() {
      //  $('#client_assessment_form').submit();
      var form = document.querySelector('#client_assessment_form');
      Rails.fire(form, 'submit');
    }, 1000);
  }

  // scoring
  $(window).scroll(function() {
    var distanceFromTop = $(this).scrollTop();
    if (distanceFromTop >= 70) {
        $('.heads-up').addClass('fixed');
    } else {
        $('.heads-up').removeClass('fixed');
    }
  });

  // pills
  $( ".pill-key" ).click(function() {
    $( this ).parent().siblings('.details').slideToggle();
    $(this).toggleClass("active");
  });

  $( ".pill-strong a" ).click(function() {
    $(this).parent().addClass('active');
    $(this).parent().siblings('.pill-medium').removeClass('active')
    $(this).parent().siblings('.pill-weak').removeClass('active')
    $(this).parent().siblings('.pill-na').removeClass('active')
  });

  $( ".pill-medium a" ).click(function() {
    $(this).parent().addClass('active');
    $(this).parent().siblings('.pill-strong').removeClass('active')
    $(this).parent().siblings('.pill-weak').removeClass('active')
    $(this).parent().siblings('.pill-na').removeClass('active')
  });

  $( ".pill-weak a" ).click(function() {
    $(this).parent().addClass('active');
    $(this).parent().siblings('.pill-strong').removeClass('active')
    $(this).parent().siblings('.pill-medium').removeClass('active')
    $(this).parent().siblings('.pill-na').removeClass('active')
  });

  $( ".pill-na a" ).click(function() {
    $(this).parent().addClass('active');
    $(this).parent().siblings('.pill-strong').removeClass('active')
    $(this).parent().siblings('.pill-medium').removeClass('active')
    $(this).parent().siblings('.pill-weak').removeClass('active')
  });

  // enable save button after any changes
  // $('.save-after-change').prop('disabled', true);
  //
  // $('input[type="text"]').keyup(function() {
  //   $('.save-after-change').prop('disabled', false);
  //   $('.submit-assessment').hide();
  // });
  //
  // $('trix-editor').keyup(function() {
  //   $('.save-after-change').prop('disabled', false);
  //   $('.submit-assessment').hide();
  // });
  //
  // $('.pill-strong, .pill-medium, .pill-weak, .pill-na').click(function() {
  //   $('.save-after-change').prop('disabled', false);
  //   $('.submit-assessment').hide();
  // });
};


$(document).on('turbolinks:load', readyFn);
