// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
import "jquery"
import "channels"
import 'select2'

require("packs/dependentApplication")
require("packs/caseStrategyCharts")
require("packs/clientAssessments")
require("packs/petitionItems")
require("packs/appCases")
require("packs/exhibits")
require("packs/components")
require("packs/select2setup")
import "chartkick/chart.js"

require("trix")
require("@rails/actiontext")

import "../controllers"
